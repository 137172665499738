import {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {RequestForgottenPassword} from '../redux/CustomerPortalCRUD'
import { ForgotPasswordForm, ForgotPasswordFormValues } from './ForgotPasswordForm'

export function ForgotPassword() {
  const history = useHistory()

  const handleSubmit = useCallback(async (values: ForgotPasswordFormValues) => {
    await RequestForgottenPassword(values.email)
  }, [])

  const logout = useCallback(() => {
    history.push('/auth/login')
  }, [history])

  return(
  <>
    <div
      className='bg-body rounded shadow-sm p-10 p-lg-15 mt-20'
      style={{maxWidth: '57vw', margin: 'auto'}}
    >
      <ForgotPasswordForm onSubmit={handleSubmit} onCancel={logout} />
    </div>
  </>)
}

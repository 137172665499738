import React, {useMemo} from 'react'
import style from '../../pages/RetailProductsStyle.module.scss'
import {Button} from '../../../../components/inputs/Button'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
interface ProductCardProps {
  product: ProductModel
  onBookNow: () => void
  config: Record<string, string>
}

export const ProductCard: React.FC<ProductCardProps> = ({product, onBookNow, config}) => {
  const image = useMemo(() => {
    if (product?.emsFile) {
      return new ImageInputValue(config?.STATIC_PUBLIC_FILE_URL, product.emsFile).url
    }
  }, [config, product.emsFile])

  return (
    <div className={style.retailProductCard}>
      <img src={image ? image : toAbsoluteUrl('/default_product_img.png')} alt='img' />
      <div className={style.cardBody}>
        <h5 className={style.cardTitle}>{product.name}</h5>
        <p className={style.cardText}>{product.description}</p>
        <Button className='w-100' variant='primary' color='white' type='button' onClick={onBookNow}>
          BOOK NOW
        </Button>
      </div>
    </div>
  )
}

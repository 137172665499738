import { useFormik } from "formik"
import { useState } from "react"
import { TextInput } from "../../../../components/inputs"
import { Button } from "react-bootstrap"
import { LoadingSpinner } from "../../../../components/utils/LoadingSpinner"
import * as Yup from 'yup'


export interface ForgotPasswordFormValues {
    email: string
  }
  
  export interface ForgotPasswordFormProps {
    initialValues?: ForgotPasswordFormValues
    onSubmit?: (values: ForgotPasswordFormValues) => void | Promise<void>
    onCancel: () => void
  }
  
  export const ForgotPasswordForm = ({
    initialValues = EMPTY_INITIAL_VALUES,
    onSubmit,
    onCancel,
  }: ForgotPasswordFormProps) => {
    const [hasErrors, setHasErrors] = useState<boolean>()
  
    const formik = useFormik({
      initialValues,
      validationSchema: forgotPasswordSchema,
      onSubmit: async (values, {setSubmitting}) => {
        setHasErrors(false)
        setSubmitting(true)
        try {
          onSubmit && (await onSubmit(values))
        } catch (e) {
          setHasErrors(true)
        } finally {
          setSubmitting(false)
        }
      },
    })
    return (
      <form className='form w-100' noValidate onSubmit={formik.handleSubmit}>
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Forgot Password?</h1>
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
        </div>
  
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}
  
        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
  
        <TextInput
          label='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          errorMessage={formik.errors.email}
          isTouched={formik.touched.email}
        />
  
        <div>
          <div className='d-flex flex-wrap justify-content-center'>
            <Button type='submit' variant='light-primary' disabled={!formik.isValid} className='me-4'>
              <LoadingSpinner loading={formik.isSubmitting}>Submit</LoadingSpinner>
            </Button>
            <Button variant='text' className='me-4' onClick={onCancel} disabled={formik.isSubmitting}>
              Cancel
            </Button>
          </div>

        </div>
      </form>
    )
  }
  
  const EMPTY_INITIAL_VALUES: ForgotPasswordFormValues = {
    email: '',
  }
  
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
  })
  
import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {put, 
  // select, 
  takeLatest} from 'redux-saga/effects'
// import {TicketGroupModel} from '../../../models/customer-portal/TicketModel'
// import {FilterModel} from '../../../models/FilterModel'
// import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
// import {PortalSearchModel} from '../../../models/customer-portal/PortalSearchModel'
// import {VoucherGroupModel} from '../../../models/customer-portal/VoucherModel'
import { CustomerUserModel } from '../../../../models/customer-portal/AuthModel'
import { BookingModel } from '../../../../models/ems/BookingModel'
import { GlobalSearchModel } from '../../../../models/GlobalSearchModel'
import { VerifyCustomerBookingAuthToken } from './CustomerPortalCRUD'
// import {
  // CustomerModelCreateFormData,
  // CustomerModelCreateParams,
  // CustomerModel,
// } from '../../../models/CustomerModel'
// import {
//   GetBookingList,
//   GetTicketList,
//   GetVoucherList,
//   VerifyCustomerBookingAuthToken,
//   GetCustomerListFlatten,
//   PostCustomer,
//   PutCustomer,
//   GetBookingDetail,
//   GetReservationDetail,
//   GetReservationList,
//   GetEvents,
// } from './CustomerPortalCRUD'
// import {CustomerUserModel} from '../../../models/customer-portal/AuthModel'
// import {BookingModel} from '../../../models/ems/BookingModel'
// // import {ProductModel} from '../../../models/ems/ProductModel'
// import {TicketPortalModel} from '../../../models/ems/TicketModel'
// import {VoucherModel} from '../../../models/svc/VoucherModel'
// import {CustomerGroupModel} from '../../../models/customer-portal/CustomerModel'
// import {ReservationPortalModel} from '../../../models/ems/ReservationMedel'
// import {EventModel} from '../../../models/ems/EventModel'
// import {BookingDetailModel} from '../../../models/customer-portal/BookingDetailModel'
// import {ReservationDetailsModel} from '../../../models/customer-portal/ReservationDetalsModel'
// import {ReservationFormValues} from '../components/wizards/ReservationWizard/ReservationWizard'
// import {BulkBookingFormValues} from '../../../models/booking-wizard/BulkBookingWizard'
// import {BookingFormValues} from '../../../models/booking-wizard/BookingWizard'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface OutletAuthState {
  user?: CustomerUserModel
  token?: string
}

export interface IOutletState {
  auth?: OutletAuthState
  bookings?: GlobalSearchModel<BookingModel>
  // vouchers?: GlobalSearchModel<VoucherModel> | PortalSearchModel<VoucherGroupModel>
  // tickets?: GlobalSearchModel<TicketPortalModel> | PortalSearchModel<TicketGroupModel>
  // customers?: GlobalSearchModel<CustomerModel> | PortalSearchModel<CustomerGroupModel>
  // bookingProducts?: GlobalSearchModel<BookingDetailModel>
  // reservationDetails?: GlobalSearchModel<ReservationDetailsModel>
  // reservations?: GlobalSearchModel<ReservationPortalModel>
  // events?: GlobalSearchModel<EventModel>
  // ticketsByBookingProduct?:
  //   | GlobalSearchModel<TicketPortalModel>
  //   | PortalSearchModel<TicketGroupModel>
  // bookingForm?: BookingFormValues
  // bookingBulkForm?: BulkBookingFormValues
  // reservationForm?: ReservationFormValues
}

const initialAuthState: IOutletState = {
  bookings: undefined,
  // reservations: undefined,
}

// ACTIONS TYPES
export const actionTypes = {
  auth: {
    login: '[Customer Portal] LOGIN',
    logout: '[Customer Portal] LOGOUT',
    requestAuth: '[Customer Portal] REQUEST AUTH',
    fulfillAuth: '[Customer Portal] FULFILL AUTH',
  },
  // bookings: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH BOOKINGS',
  //     success: '[Customer Portal] SEARCH BOOKINGS SUCCESS',
  //     failed: '[Customer Portal] SEARCH BOOKINGS FAILED',
  //   },
  // },
  // bookingForm: {
  //   bookingForm: '[Customer Portal] BOOKING FORM',
  // },
  // bookingBulkForm: {
  //   bookingBulkForm: '[Customer Portal] BOOKING BULK FORM',
  // },
  // reservationForm: {
  //   reservationForm: '[Customer Portal] RESERVATION FORM',
  // },

  // reservations: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH RESERVATION',
  //     success: '[Customer Portal] SEARCH RESERVATION SUCCESS',
  //     failed: '[Customer Portal] SEARCH RESERVATION FAILED',
  //   },
  // },
  // tickets: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH TICKETS',
  //     success: '[Customer Portal] SEARCH TICKETS SUCCESS',
  //     failed: '[Customer Portal] SEARCH TICKETS FAILED',
  //   },
  // },
  // ticketsByBookingProduct: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH TICKETS BY BOOKING PRODUCT',
  //     success: '[Customer Portal] SEARCH TICKETS BY BOOKING PRODUCT SUCCESS',
  //     failed: '[Customer Portal] SEARCH TICKETS BY BOOKING PRODUCT FAILED',
  //   },
  // },
  // vouchers: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH VOUCHERS',
  //     success: '[Customer Portal] SEARCH VOUCHERS SUCCESS',
  //     failed: '[Customer Portal] SEARCH VOUCHERS FAILED',
  //   },
  // },
  // customers: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH CUSTOMER',
  //     success: '[Customer Portal] SEARCH CUSTOMER SUCCESS',
  //     failed: '[Customer Portal] SEARCH CUSTOMER FAILED',
  //   },
  //   create: {
  //     execute: '[Customer Portal] CREATE CUSTOMER',
  //     success: '[Customer Portal] CREATE CUSTOMER SUCCESS',
  //     failed: '[Customer Portal] CREATE CUSTOMER FAILED',
  //   },
  //   update: {
  //     execute: '[Customer Portal] UPDATE CUSTOMER',
  //     success: '[Customer Portal] UPDATE CUSTOMER SUCCESS',
  //     failed: '[Customer Portal] UPDATE CUSTOMER FAILED',
  //   },
  //   delete: {
  //     execute: '[Customer Portal] DELETE CUSTOMER',
  //     success: '[Customer Portal] DELETE CUSTOMER SUCCESS',
  //     failed: '[Customer Portal] DELETE CUSTOMER FAILED',
  //   },
  // },
  // bookingProducts: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH BOOKING PRODUCT',
  //     success: '[Customer Portal] SEARCH BOOKING PRODUCT SUCCESS',
  //     failed: '[Customer Portal] SEARCH BOOKING PRODUCT FAILED',
  //   },
  // },

  // reservationDetails: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH RESERVATION DETAILS',
  //     success: '[Customer Portal] SEARCH RESERVATION DETAILS SUCCESS',
  //     failed: '[Customer Portal] SEARCH RESERVATION DETAILS FAILED',
  //   },
  // },

  // events: {
  //   search: {
  //     execute: '[Customer Portal] SEARCH EVENT',
  //     success: '[Customer Portal] SEARCH EVENT SUCCESS',
  //     failed: '[Customer Portal] SEARCH EVENT FAILED',
  //   },
  // },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'outlet',
    whitelist: ['auth', 
    // 'bookingForm', 'bookingBulkForm', 'reservationForm'
  ],
  },
  (state: IOutletState = initialAuthState, action: ActionWithPayload<IOutletState>) => {
    switch (action.type) {
      case actionTypes.auth.login: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.requestAuth: {
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.logout: {
        return {
          ...state,
          auth: undefined,
        }
      }
      case actionTypes.auth.fulfillAuth: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: auth?.user,
          },
        }
      }
      // case actionTypes.bookings.search.success: {
      //   const bookings = action.payload?.bookings
      //   return {...state, bookings}
      // }

      // case actionTypes.reservations.search.success: {
      //   const reservations = action.payload?.reservations
      //   return {...state, reservations}
      // }
      // case actionTypes.tickets.search.success: {
      //   const tickets = action.payload?.tickets
      //   return {...state, tickets}
      // }

      // case actionTypes.ticketsByBookingProduct.search.success: {
      //   const ticketsByBookingProduct = action.payload?.ticketsByBookingProduct
      //   return {...state, ticketsByBookingProduct}
      // }

      // case actionTypes.vouchers.search.success: {
      //   const vouchers = action.payload?.vouchers
      //   return {...state, vouchers}
      // }
      // case actionTypes.customers.search.success: {
      //   const customers = action.payload?.customers
      //   return {...state, customers}
      // }

      // case actionTypes.bookingProducts.search.success: {
      //   const bookingProducts = action.payload?.bookingProducts
      //   return {...state, bookingProducts}
      // }

      // case actionTypes.reservationDetails.search.success: {
      //   const reservationDetails = action.payload?.reservationDetails
      //   return {...state, reservationDetails}
      // }

      // case actionTypes.events.search.success: {
      //   const events = action.payload?.events
      //   return {...state, events}
      // }
      // case actionTypes.bookingForm: {
      //   const bookingForm = action.payload?.bookingForm
      //   return {...state, bookingForm}
      // }
      // case actionTypes.bookingBulkForm: {
      //   const bookingBulkForm = action.payload?.bookingBulkForm
      //   return {...state, bookingBulkForm}
      // }
      // case actionTypes.reservationForm: {
      //   const reservationForm = action.payload?.reservationForm
      //   return {...state, reservationForm}
      // }

      default:
        return state
    }
  }
)

// AFTER EFFECT
export function* saga() {
  yield takeLatest(actionTypes.auth.login, function* () {
    yield put(actions.auth.requestAuth())
  })

  yield takeLatest(actionTypes.auth.requestAuth, function* () {
    const {data} = yield VerifyCustomerBookingAuthToken()
    yield put(actions.auth.fulfillUser(data.user))
  })

  // yield takeLatest([actionTypes.tickets.search.execute], function* refresh() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-ticket']
  //     )
  //     const {data} = yield GetTicketList(filter)
  //     yield put(actions.tickets.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.tickets.searchFailed())
  //   }
  // })

  // yield takeLatest([actionTypes.ticketsByBookingProduct.search.execute], function* refresh() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-ticket-by-bookingProduct']
  //     )
  //     const {data} = yield GetTicketList(filter)
  //     yield put(actions.ticketsByBookingProduct.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.ticketsByBookingProduct.searchFailed())
  //   }
  // })

  // yield takeLatest([actionTypes.bookings.search.execute], function* refresh() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-booking']
  //     )
  //     const {data} = yield GetBookingList(filter)
  //     yield put(actions.bookings.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.bookings.searchFailed())
  //   }
  // })

  // yield takeLatest([actionTypes.reservations.search.execute], function* refresh() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-reservation']
  //     )
  //     const {data} = yield GetReservationList(filter)
  //     yield put(actions.reservations.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.reservations.searchFailed())
  //   }
  // })

  // yield takeLatest([actionTypes.vouchers.search.execute], function* refresh() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-voucher']
  //     )
  //     const {data} = yield GetVoucherList(filter)
  //     yield put(actions.vouchers.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.vouchers.searchFailed())
  //   }
  // })

  // yield takeLatest(
  //   actionTypes.customers.create.execute,
  //   function* afterEffectSaga(action: Required<ActionWithPayload<CustomerModelCreateFormData>>) {
  //     try {
  //       yield PostCustomer(action.payload)
  //       yield put(actions.customers.createSuccess())
  //     } catch (e) {
  //       yield put(actions.customers.createFailed())
  //     }
  //   }
  // )

  // yield takeLatest(
  //   actionTypes.customers.update.execute,
  //   function* afterEffectSaga(
  //     action: Required<ActionWithPayload<{customer: CustomerModelCreateFormData; code: string}>>
  //   ) {
  //     try {
  //       yield PutCustomer(action.payload.customer, action.payload.code)
  //       yield put(actions.customers.updateSuccess())
  //     } catch (e) {
  //       yield put(actions.customers.updateFailed())
  //     }
  //   }
  // )

  // yield takeLatest([actionTypes.customers.search.execute], function* refresh() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-customer']
  //     )
  //     const {data} = yield GetCustomerListFlatten(filter)
  //     yield put(actions.customers.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.customers.searchFailed())
  //   }
  // })

  // yield takeLatest(actionTypes.bookingProducts.search.execute, function* refreshEvent() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-booking-product']
  //     )
  //     const {data} = yield GetBookingDetail(filter)
  //     yield put(actions.bookingProducts.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.bookingProducts.searchFailed())
  //   }
  // })

  // yield takeLatest(actionTypes.reservationDetails.search.execute, function* refreshEvent() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-reservation-details']
  //     )
  //     const {data} = yield GetReservationDetail(filter)
  //     yield put(actions.reservationDetails.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.reservationDetails.searchFailed())
  //   }
  // })

  // yield takeLatest(actionTypes.events.search.execute, function* refreshEvent() {
  //   try {
  //     const filter: FilterModel = yield select(
  //       (state) => state.system.filters['customer-portal-event']
  //     )
  //     const {data} = yield GetEvents(filter)
  //     yield put(actions.events.searchSuccess(data))
  //   } catch (e) {
  //     yield put(actions.events.searchFailed())
  //   }
  // })
}

// ACTIONS
export const actions = {
  auth: {
    login: (token: string) => ({type: actionTypes.auth.login, payload: {auth: {token}}}),
    logout: () => ({type: actionTypes.auth.logout}),
    requestAuth: () => ({type: actionTypes.auth.requestAuth}),
    fulfillUser: (user: CustomerUserModel) => ({
      type: actionTypes.auth.fulfillAuth,
      payload: {
        auth: {
          user,
        },
      },
    }),
  },
  // bookings: {
  //   search: () => ({type: actionTypes.bookings.search.execute}),
  //   searchSuccess: (data: GlobalSearchModel<BookingModel>) => ({
  //     type: actionTypes.bookings.search.success,
  //     payload: {bookings: data},
  //   }),
  //   searchFailed: () => ({type: actionTypes.bookings.search.failed}),
  // },
  // bookingForm: {
  //   // CREATE BOOKING FORM
  //   setBookingForm: (payload?: BookingFormValues) => ({
  //     type: actionTypes.bookingForm,
  //     payload: {
  //       bookingForm: payload,
  //     },
  //   }),

  //   resetBookingForm: () => ({
  //     type: actionTypes.bookingForm,
  //   }),
  // },

  // bookingBulkForm: {
  //   // CREATE BOOKING FORM
  //   setBookingBulkForm: (payload?: BulkBookingFormValues) => ({
  //     type: actionTypes.bookingBulkForm,
  //     payload: {
  //       bookingBulkForm: payload,
  //     },
  //   }),

  //   resetBookingBulkForm: () => ({
  //     type: actionTypes.bookingBulkForm,
  //   }),
  // },

  // reservationForm: {
  //   // CREATE RESERVATION FORM
  //   setReservationForm: (payload?: ReservationFormValues) => ({
  //     type: actionTypes.reservationForm,
  //     payload: {
  //       reservationForm: payload,
  //     },
  //   }),

  //   resetReservationForm: () => ({
  //     type: actionTypes.reservationForm,
  //   }),
  // },

  // reservations: {
  //   search: () => ({type: actionTypes.reservations.search.execute}),
  //   searchSuccess: (data: GlobalSearchModel<ReservationPortalModel>) => ({
  //     type: actionTypes.reservations.search.success,
  //     payload: {reservations: data},
  //   }),
  //   searchFailed: () => ({type: actionTypes.reservations.search.failed}),
  // },
  // tickets: {
  //   search: () => ({type: actionTypes.tickets.search.execute}),
  //   searchSuccess: (
  //     data: GlobalSearchModel<TicketPortalModel> | PortalSearchModel<TicketGroupModel>
  //   ) => ({
  //     type: actionTypes.tickets.search.success,
  //     payload: {tickets: data},
  //   }),
  //   searchFailed: () => ({type: actionTypes.tickets.search.failed}),
  // },

  // ticketsByBookingProduct: {
  //   search: () => ({type: actionTypes.ticketsByBookingProduct.search.execute}),
  //   searchSuccess: (
  //     data: GlobalSearchModel<TicketPortalModel> | PortalSearchModel<TicketGroupModel>
  //   ) => ({
  //     type: actionTypes.ticketsByBookingProduct.search.success,
  //     payload: {ticketsByBookingProduct: data},
  //   }),
  //   searchFailed: () => ({type: actionTypes.ticketsByBookingProduct.search.failed}),
  // },

  // vouchers: {
  //   search: () => ({type: actionTypes.vouchers.search.execute}),
  //   searchSuccess: (
  //     data: GlobalSearchModel<VoucherModel> | PortalSearchModel<VoucherGroupModel>
  //   ) => ({
  //     type: actionTypes.vouchers.search.success,
  //     payload: {vouchers: data},
  //   }),
  //   searchFailed: () => ({type: actionTypes.vouchers.search.failed}),
  // },

  // customers: {
  //   search: () => ({type: actionTypes.customers.search.execute}),
  //   searchSuccess: (data: GlobalSearchModel<CustomerModel>) => ({
  //     type: actionTypes.customers.search.success,
  //     payload: {customers: data},
  //   }),
  //   searchFailed: () => ({type: actionTypes.customers.search.failed}),
  //   create: (payload: CustomerModelCreateParams) => ({
  //     type: actionTypes.customers.create.execute,
  //     payload,
  //   }),
  //   createSuccess: () => ({type: actionTypes.customers.create.success}),
  //   createFailed: () => ({type: actionTypes.customers.create.failed}),

  //   update: (payload: CustomerModelCreateParams) => ({
  //     type: actionTypes.customers.update.execute,
  //     payload,
  //   }),
  //   updateSuccess: () => ({type: actionTypes.customers.update.success}),
  //   updateFailed: () => ({type: actionTypes.customers.update.failed}),

  //   delete: (codes: string[]) => ({type: actionTypes.customers.delete.execute, payload: codes}),
  //   deleteSuccess: () => ({type: actionTypes.customers.delete.success}),
  //   deleteFailed: () => ({type: actionTypes.customers.delete.failed}),
  // },

  // bookingProducts: {
  //   search: () => ({
  //     type: actionTypes.bookingProducts.search.execute,
  //   }),
  //   searchSuccess: (bookingProducts: GlobalSearchModel<ProductModel>) => ({
  //     type: actionTypes.bookingProducts.search.success,
  //     payload: {bookingProducts},
  //   }),
  //   searchFailed: () => ({type: actionTypes.bookingProducts.search.failed}),
  // },

  // reservationDetails: {
  //   search: () => ({
  //     type: actionTypes.reservationDetails.search.execute,
  //   }),
  //   searchSuccess: (reservationDetails: GlobalSearchModel<ReservationDetailsModel>) => ({
  //     type: actionTypes.reservationDetails.search.success,
  //     payload: {reservationDetails},
  //   }),
  //   searchFailed: () => ({type: actionTypes.reservationDetails.search.failed}),
  // },

  // events: {
  //   search: () => ({
  //     type: actionTypes.events.search.execute,
  //   }),
  //   searchSuccess: (events: GlobalSearchModel<EventModel>) => ({
  //     type: actionTypes.events.search.success,
  //     payload: {events},
  //   }),
  //   searchFailed: () => ({type: actionTypes.events.search.failed}),
  // },
}

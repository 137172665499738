import * as Yup from 'yup'
import {useFormik} from 'formik'
import {SetStateAction, useCallback, useMemo, useState} from 'react'
import {TextInput} from '../../../../components/inputs'
import { useOnChange } from '../../../../components/hooks/useOnChange'
import { useStepperState } from '../../pages/StepperStateContext'
import { FormStep } from '../booking-wizard/BookingWizard'
export interface PasswordResetFormValues {
  newPassword: string
  confirmPassword: string
}

export interface PasswordResetFormProps {
  onSubmit: (values: PasswordResetFormValues) => void | Promise<void>
  clickedPrevious: boolean
  setPrevious: (value: boolean) => void
  setCurrentStep: (value: SetStateAction<FormStep>) => void

}

const passwordResetFormValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .test('is-password-match', 'Password does not match', (value, context) => {
      const formValues = context.parent as PasswordResetFormValues
      if (formValues.newPassword) {
        return formValues.newPassword === value
      }
      return false
    }),
})

const INITIAL_VALUES: PasswordResetFormValues = {
  confirmPassword: '',
  newPassword: '',
}

export const PasswordResetForm = ({
  onSubmit,
  clickedPrevious,
  setPrevious,
  setCurrentStep,
}: PasswordResetFormProps) => {
  const {resetSubmit, setResetSubmit} = useStepperState()
  const [hasErrors, setHasErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formik = useFormik<PasswordResetFormValues>({
    initialValues: INITIAL_VALUES,
    validationSchema: passwordResetFormValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await onSubmit(values)
      } catch (e) {
        setHasErrors(true)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const errorMessage = useMemo(() => {
    if (hasErrors) {
      return 'Sorry, looks like there are some errors detected, please try again.'
    }
    const formikErrorMessages = Object.values(formik.errors)
    if (formikErrorMessages[0]) {
      return formikErrorMessages[0]
    }
    return null
  }, [hasErrors, formik.errors])

  useOnChange(resetSubmit, () => {
    if (resetSubmit) {
      formik.handleSubmit()
    }
  })
  useOnChange(clickedPrevious, () => {
  if (clickedPrevious) {
      setCurrentStep(FormStep.LOGIN)
      setResetSubmit(false)
      setPrevious(false)
      handleBack()
    }
  })

  const handleBack = useCallback(() => {
    formik.resetForm()
  }, [formik])


  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        autoComplete='off'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* <h1 className='text-dark mb-3'>Please reset your password.</h1> */}
          <div className='text-gray-400 fw-bold fs-4'>Enter a new password.</div>
        </div>
        {!isLoading && errorMessage && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        <TextInput
          isTouched={formik.touched.newPassword}
          errorMessage={formik.errors.newPassword}
          type='password'
          label='New Password'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
        />
        <TextInput
          isTouched={formik.touched.confirmPassword}
          errorMessage={formik.errors.confirmPassword}
          type='password'
          label='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
        />
        {/* <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button disabled={isLoading} type='submit' className='btn btn-lg btn-light-primary me-4'>
            {!isLoading && <span className='indicator-label'>Continue</span>}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/logout'>
            <button
              type='button'
              id='kt_logout_password_reset_form_cancel_button'
              className='btn btn-lg btn-text'
              disabled={formik.isSubmitting}
            >
              Cancel
            </button>
          </Link>
        </div> */}
      </form>
    </>
  )
}

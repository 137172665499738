import {SetStateAction, useCallback, useState} from 'react'
import {useFormik} from 'formik'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {FormStep} from '../BookingWizard'
import {TextInput} from '../../../../../components/inputs'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {useFormikStringInputHelpers} from '../../../../../components/inputs/hooks/useFormikStringInputHelpers'
import * as yup from 'yup'
import {EVENT_CODE} from '../../../../../../config/env'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {SignUpCustomer} from '../../../../customer-portal/redux/CustomerPortalCRUD'
import { useStepperState } from '../../../pages/StepperStateContext'
import { MobileNumberParser } from '../../../../../utils/MobileNumberParser'

export interface RegistrationCustomerInformationValues {
  firstName: string
  lastName: string
  email: string
  mobile: string
  // password: string
}

export interface BookingWizardRegistrationInformationProps {
  clickedPrevious: boolean
  setRegisterPrevious: (value: boolean) => void
  setCurrentStep: (value: SetStateAction<FormStep>) => void
}

export const BookingWizardRegistrationInformation = ({
  clickedPrevious,
  setCurrentStep,
  setRegisterPrevious,
}: BookingWizardRegistrationInformationProps) => {
  const {registerSubmit, setClickedRegister,setRegisterSubmit} = useStepperState()
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)


  const regformik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: async (values) => {
      if (EVENT_CODE) {
        try {
          const payload = getPayload(values)
          await SignUpCustomer(payload)
          setHasSubmitted(true)
          setCurrentStep(FormStep.LOGIN)
        } catch (e: any) {
          pushError(e)
        }
  
        setClickedRegister(false)
        setRegisterSubmit(false)
      }
    },
    validateOnMount: true,
  })
  const textInputHelpers = useFormikTextInputHelpers(regformik)
  const stringInputHelpers = useFormikStringInputHelpers(regformik)

  useOnChange(registerSubmit, () => {
    if (registerSubmit) {
      regformik.handleSubmit()
      setClickedRegister(false)
      setRegisterSubmit(false)
    }
  })
  useOnChange(clickedPrevious, () => {
    if (clickedPrevious && !hasSubmitted) {
      setRegisterPrevious(false)
      setCurrentStep(FormStep.CALENDAR)
      setClickedRegister(false)
      handleBack()
    } else if (clickedPrevious) {
      setCurrentStep(FormStep.LOGIN)
      setClickedRegister(false)
      setRegisterPrevious(false)
      handleBack()
    }
  })

  const handleBack = useCallback(() => {
    regformik.resetForm()
  }, [regformik])


  return (
    <>
      <div
        className='bg-body rounded shadow-sm p-10 p-lg-15'
        style={{maxWidth: '57vw', margin: 'auto'}}
      >
        <form
          className='form w-100'
          onSubmit={regformik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='row'>
            <div className='col-12'>
              <TextInput
                placeholder='Enter First Name'
                label='First Name'
                {...textInputHelpers.getProps('firstName')}
              />
            </div>
            <div className='col-12'>
              <TextInput
                placeholder='Enter Last Name'
                label='Last Name'
                {...textInputHelpers.getProps('lastName')}
              />
            </div>
            <div className='col-12'>
              <TextInput
                placeholder='Enter a valid Email'
                label='Email'
                {...textInputHelpers.getProps('email')}
              />
            </div>

            <div className='col-12'>
              <MobileNumberInput
                placeholder='Enter a valid Mobile Number'
                label='Mobile number'
                helperMessage='Ex: +971551234567'
                {...stringInputHelpers.getProps('mobile')}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export const getPayload = (
  customer: RegistrationCustomerInformationValues
) => {
  const payload: CreateCustomerParams = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    email: customer.email,
    mobile: customer.mobile,
    // password: 'Wnt@1234',
  }

  return payload
}

export const INITIAL_VALUES: RegistrationCustomerInformationValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  // password: '',
}

export const validationSchema = yup.object().shape({
  email: yup.string().required('Please enter an email').email('Please enter a valid email'),
  firstName: yup.string().required('Please enter first name'),
  lastName: yup.string().required('Please enter last name'),
  mobile: yup
    .string()
    .test('is-mobile-number', 'Please enter a valid mobile number', (value) => {
      if (value) {
        const mobileNumber = new MobileNumberParser(value)
        return mobileNumber.isValidMobileNumber()
      }
      return true
    })
    .required('Please enter a valid number'),
})

export interface CreateCustomerParams {
  firstName: string
  lastName: string
  email: string
  mobile: string
  // password: string
}

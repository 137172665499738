import {FC} from 'react'

export const SvcResources: FC = () => {
  return (
    <div className='w-100 text-center h-100'>
      <div className='position-relative' style={{top: '30%'}}>
        <p className='text-gray-800 fs-1 fw-bolder mb-1'>Coming soon</p>
        <p className='text-gray-800 fs-5 fw-bolder mb-1'>
          We're working hard to bring you something amazing. Stay tuned!
        </p>
      </div>
    </div>
  )
}

import React, {useMemo} from 'react'
import clsx from 'clsx'
import style from '../../pages/EventListStyle.module.scss'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { EventModel } from '../../../../models/ems/EventModel'
import {ProductModel} from '../../../../models/ems/ProductModel'

interface EventCardProps {
  event?: EventModel | ProductModel
  onBookNow?: () => void
  config?: Record<string, string> | null
}

export const EventCard: React.FC<EventCardProps> = ({event, onBookNow, config}) => {
  const image = useMemo(() => {
    if (event?.emsFile && config) {
      return new ImageInputValue(config.STATIC_PUBLIC_FILE_URL, event.emsFile).url
    } else {
      return null
    }
  }, [config, event])

  return (
    <div
      className='card bg-dark text-white'
      style={{width: '350px', height: '350px', marginBottom: '40px', marginRight: '40px'}}
      onClick={onBookNow}
    >
      <img src={image ? image : toAbsoluteUrl('/default_product_img.png')} alt='img' />
      <div className={clsx('align-items-end', style.overlay)}>
        <h5 className={style.cardTitle}>{event?.name}</h5>
        <p className={style.cardDescription}>{event?.description}</p>
      </div>
    </div>
  )
}
